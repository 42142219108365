import React, { useEffect } from "react";
import { SignInWrapper } from "./style";
import { useTranslation } from "react-i18next";
import SvgCeCmeLogo from "components/SvgComponent/CeCmeLogo";
import AdminFooter from "components/AdminFooter";
import _ from "lodash";

const HomePage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const tokenData = localStorage.getItem("accessToken");
    if (!_.isEmpty(tokenData)) {
      window.userManager
        .signinRedirect()
        .then(function (data: any) {
          /* Do nothing */
        })
        .catch(function (err: any) {
          /* Do nothing */
        });
    }
  }, []);

  const signin = () => {
    window.userManager
      .signinRedirect()
      .then(function (data: any) {})
      .catch(function (err: any) {});
  };
  return (
    <SignInWrapper>
      <div className="main-container">
        <div className="header-div d-flex mb-4">
          <div className="ce-main-logo-div d-flex">
            <img
              src={process.env.PUBLIC_URL + "/images/ce_logo.svg"}
              className="main-content-logo logo-main headerLogo-style"
              alt="aha-ce-logo"
            />
          </div>
        </div>
        <aui-banner
          alt="AHA Home"
          type="primary"
          image={process.env.PUBLIC_URL + "/images/iStock-941446382_super.jpg"}
        >
          <h1 className="aui-banner-title mt-2 mb-3" id="main-content">
            {t<string>("Welcome to CE Service")}
          </h1>
          <p className="mb-3 aui-banner-desc">
            {t<string>(
              "Continuing education (CE) services application is a stand-alone system that interfaces with multiple vendor systems (Internal and external LMS), manages accreditation credit claim program details, performs a real time validation on individual learner activity received from each system and generate reports for AHA."
            )}
          </p>
          <aui-button
            aria-describedby="main-content"
            size="medium"
            onClick={signin}
          >
            {t<string>("signIn")} &nbsp;|&nbsp; {t<string>("signUp")}
          </aui-button>
        </aui-banner>
        <AdminFooter />
      </div>
    </SignInWrapper>
  );
};

export default HomePage;
