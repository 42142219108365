import React, { useMemo, useRef, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import AdminHeaderWrapper from "./styled";
import { useTranslation } from "react-i18next";

import SvgCeCmeLogo from "../../components/SvgComponent/CeCmeLogo";
import { showToast } from "components/Toast/toast.slice";
import { Signout } from "services/api/AdminHeader.api";
import store from "app/store";
import { LocalStorageKeys } from "../../common/constants/localStorage";
import { useRBACContext } from "@impelsysinc/react-rbac";
import AuiIcon from "components/IconComponent/AuiIcon";

const Global: any = global;

export const AdminHeader = (props: any) => {
  const { t } = useTranslation();

  const signout = async () => {
    const res = await Signout();
    if (res?.status === 200) {
      localStorage.removeItem("Authorization");
      localStorage.removeItem(LocalStorageKeys.ACCESS_TOKEN);
      localStorage.removeItem(LocalStorageKeys.REFRESH_TOKEN);
      localStorage.removeItem(LocalStorageKeys.PERMISSIONS);
      localStorage.removeItem(LocalStorageKeys.API_PERMISSIONS);
      Global.userManager.signoutRedirect();
    } else {
      store.dispatch(
        showToast({
          title: "Signout",
          message: res?.data,
        })
      );
    }
  };

  const { canAccess } = useRBACContext();

  const actions = useMemo(
    () => ({
      list: canAccess({
        resourceType: "component",
        resource: "userManagement",
        action: "get.all",
      }),
    }),
    [canAccess]
  );

  const actionRef = useRef({ ...actions });
  useEffect(() => {
    actionRef.current = actions;
  }, [actions]);

  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 991;

  return (
    <AdminHeaderWrapper
      className="aui-main-header aui-pri-header container"
      style={!isMobile ? { maxWidth: "100%", minWidth: "70%" } : {}}
    >
      <a href="#maincontent" className="aui-skip-content">
        <> {t<string>("skipToMainContent")} </>
      </a>
      <nav className="navbar navbar-expand-lg justify-content-between aui-header-content mx-auto aui-pri-header-t">
        <img 
          src={process.env.PUBLIC_URL + "/images/ce_logo.svg"}
          className="main-content-logo logo-main headerLogo-style"
          alt="aha-ce-logo"
        />

        <button
          className="navbar-toggler ml-2 header-hamburger"
          type="button"
          data-toggle="collapse"
          data-target="#adminHeaderCollapse"
          aria-controls="adminHeaderCollapse1"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={props.toggle}
        >
          <AuiIcon
            elementObject={{
              svgclass: "aui-icon-menubar",
              pathclass: "aui-path-menubar",
              icon: "menubar",
              svgwidth: "20",
              svgheight: "20",
            }}
          ></AuiIcon>
        </button>
        <div
          className="justify-content-lg-end align-items-baseline collapse navbar-collapse aui-pri-nav"
          id="adminHeaderCollapse1"
        >
          {actionRef.current.list && (
            <NavLink
              to="/users"
              aria-label="User Management"
              className="user-management"
            >
              <> {t<string>("userManagement")}</>
            </NavLink>
          )}
          <ul className="navbar-nav ml-lg-3 flex-lg-row flex-column">
            <li className="d-flex nav-item dropdown pl-lg-3 flex-column">
              <button
                type="button"
                className="btn btn-text dropdown-toggle flex-grow-1 text-left"
                id="accountdropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="pr-1">
                  <AuiIcon
                    elementObject={{
                      svgclass: "aui-icon-avatarround",
                      pathclass: "aui-path-avatarround",
                      icon: "avatarround",
                      svgwidth: "20",
                      svgheight: "20",
                    }}
                  ></AuiIcon>
                </span>
                <> {t<string>("myAccount")}</>
              </button>
              <div
                className="dropdown-menu p-lg-0 aui-header-dropdown"
                aria-labelledby="accountdropdown"
              >
                <div
                  onClick={signout}
                  className="dropdown-item py-2"
                  role="button"
                  data-testid="signout"
                  onKeyUp={signout}
                  tabIndex={0}
                >
                  <> {t<string>("signOut")}</>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </AdminHeaderWrapper>
  );
};
export default AdminHeader;
