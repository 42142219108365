import styled from "styled-components";

const LeftPanelWrapper = styled.div`
  .active {
    background: transparent;
    color: #c10e21;
    border-left: 3px solid #c10e21;
  }

  .custom-report-nav-active {
    color: #c10e21 !important;
  }

  .headerLogo-style {
    @media screen and (min-width: 768px) {
      width: 215px;
      height: auto;
    }

    @media screen and (min-width: 992px) and (max-width: 1023px) {
      width: 165px;
      height: 75px;
      position: absolute;
      top: 0px;
    }

    @media screen and (min-width: 1024px) {
      width: 175px;
      height: 75px;
      position: absolute;
      top: 11px;
    }

    @media screen and (min-width: 1440px) {
      width: 215px;
      height: 70px;
      position: absolute;
      top: 11px;
    }

    @media screen and (max-width: 767px) {
      width: 170px;
      height: 60px;
    }
  }

  .nodatafound {
    h3 {
      font-size: 16px;
    }
  }

  .custom-report-menu .aha-icon-arrow-down,
  .certificate_submenu .aha-icon-arrow-down {
    font-size: 0.5rem;
    margin: 0 0.7rem 0 auto;
    color: #c2342c;
    cursor: pointer;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  @media screen and (min-width: 992px) {
    .pos_arrow {
      position: absolute;
      top: 16px;
      right: 20px;
    }
  }

  .Accr_parentMenu {
    position: relative;
    .certi_substyle {
      margin-bottom: 0;
      margin-top: -5px;
      li a {
        padding-top: 0.175rem;
        padding-bottom: 0.175rem;
        font-size: 1rem;
        padding-left: 4.875rem !important;
        @media (min-width: 992px) and (max-width: 1200px) {
          font-size: 0.875rem;
          padding-left: 3.875rem !important;
        }
        @media screen and (max-width: 767px) {
          padding-left: 4.2rem !important;
        }
        @media screen and (max-width: 575px) {
          padding-left: 3.9rem !important;
        }
      }
    }
    a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media screen and (max-width: 991px) {
        justify-content: flex-start;
      }
    }
  }

  @media screen and (min-device-width: 426px) and (max-device-width: 991px) {
    .my-report-menu-title,
    .my-report-menu-title-active {
      padding-left: 12.5% !important;
    }

    .saved-report-title {
      padding-left: 17% !important;
    }

    .d-width {
      width: 94.5% !important;
    }

    nav > div > ul > li > button {
      p {
        width: 89% !important;
        padding: 0.1rem;
        margin-bottom: 0px !important;
      }
    }
  }

  @media screen and (min-device-width: 320px) and (max-device-width: 425px) {
    .my-report-menu-title,
    .my-report-menu-title-active {
      padding-left: 20% !important;
    }

    .d-width {
      width: 87% !important;
    }

    nav > div > ul > li > button {
      p {
        width: 75%;
        padding: 0.1rem;
        margin-bottom: 0px !important;
      }
    }
  }
  nav > div > ul > li > button {
    .nav-item-text {
      width: 100%;
      padding: 0.1rem 0.1rem 0.1rem 0.725rem;
      margin-bottom: 0px !important;
      @media screen and (max-width: 991px) {
        margin-right: 7px;
      }
    }
    span {
      svg {
        width: 1.375rem;
        height: 1.375rem;
        display: inline-block;
      }
    }
  }
  @media screen and (min-width: 992px) {
    .d-width {
      width: 85%;
    }

    .nav-side-icons-only {
      text-align: center;
      width: 100%;
      div {
        padding: 0px 8px 14px;
        margin-bottom: 19px;
      }
      img {
        width: 1.7rem;
        height: 1.7rem;
      }
    }
  }

  .certi_substyle.collapse {
    transition: all 0.6s ease;
    transform-origin: left top;
    transform: scaleY(0);
  }

  .certi_substyle.show {
    transform: scaleY(1);
  }

  .submenuStyle {
    background-color: #ebebeb !important;
  }
  .custom-report-menu {
    position: relative;
  }

  .my-report-menu {
    display: flex;
    position: relative;
    cursor: pointer;
  }

  .my-report-menu-title,
  .my-report-menu-title-active {
    margin-right: 1rem;
    padding-left: 4.215rem;
    font-weight: 500;
  }

  .my-report-menu-title > p {
    font-size: large;
  }

  .my-report-menu-title-active > p {
    font-size: large;
    color: #c10e21;
  }

  .my-report-menu-icon {
    position: absolute;
    right: 1.3rem;
  }

  .saved-report-title {
    padding-left: 33%;
    cursor: pointer;
  }

  .saved-report-title > p {
    font-size: 14px;
    font-weight: 500;
  }

  .custom-report-menu .aha-icon-arrow-down.icon_rotate,
  .certificate_submenu .aha-icon-arrow-down.icon_rotate {
    margin: 0 0.4rem 0 auto;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  .hide_desktop {
    display: none;
  }

  @media screen and (max-width: 991px) {
    .show_mobile {
      display: block;
    }
    .mob_signout {
      color: #000 !important;
      text-align: right !important;

      &:focus {
        outline: 0px !important;
        box-shadow: none !important;
      }
    }
    .navbar-nav .dropdown-menu {
      position: absolute;
      transform: translate3d(5px, 58px, 0px);
      top: -12px !important;
      left: 0px !important;
      will-change: transform;
      width: 95% !important;

      .dropdown-item {
        text-align: center;
      }
    }

    #sidenavcollapseSignOut .dropdown-item:active {
      color: #c10e21;
      background-color: transparent;
    }
  }

  .aui-sidenav {
    .aui-sidenav-acc-header[aria-expanded="true"] {
      .acc-btn-arrow {
        margin-right: 0;
      }
    }
  }
  .section-container {
    + .section-container {
      border-top: 1px solid #ebebeb;
    }
    .section-item {
      max-height: calc(50vh - 152px);
      overflow: auto;
      min-height: 100px;
      @media screen and (min-width: 992px) and (max-width: 1200px) {
        max-height: calc(50vh - 138px);
      }
      @media screen and (max-width: 991px) {
        max-height: calc(50vh - 130px);
      }
      @media screen and (max-width: 767px) {
        max-height: calc(50vh - 140px);
      }
      @media screen and (max-width: 575px) {
        max-height: calc(50vh - 118px);
      }
    }
    .btn {
      @media screen and (min-width: 992px) and (max-width: 1200px) {
        font-size: 14px;
      }
    }
  }

  .section-heading {
    padding: 1rem 1rem 1rem 2rem;
    @media screen and (min-width: 992px) and (max-width: 1200px) {
      padding: 1rem;
    }
    @media screen and (max-width: 767px) {
      padding: 1.25rem;
    }
  }
  .section-header-text {
    color: #222328;
    font-weight: 600;
  }

  .expandButton button {
    display: flex;
    justify-content: center;
    right: -12px;
    top: 61px;
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    border: 1px solid #70707066;
    border-radius: 6px;
    font-size: 0.5rem;
    align-items: center;
    padding: 0px;
    aui-icon::part(icon) {
      vertical-align: text-top;
    }
    .aha-icon-arrow-down:before,
    .aha-icon-hamburger:before {
      width: auto;
      margin: 0;
      opacity: 1;
    }
    @media screen and (max-width: 991px) {
      top: -30px;
      right: 10px;
    }
  }

  .leftDireaction {
    transform: rotateZ(90deg);
    padding: 0px;
  }

  .rightDireaction {
    transform: rotateZ(270deg);
    padding-top: 2px;
    padding-left: 0px;
    padding-right: 9px;
    padding-bottom: 0px;
  }

  @media screen and (min-width: 992px) and (max-width: 1200px) {
    .aui-sidenav .btn {
      padding: 10px;
    }
  }
  .side-nav-wrapper {
    display: block;
    &.hide {
      display: none;
    }
  }

  .report-filter-nav-wrapper {
    margin-top: -30px;
    @media screen and (max-width: 991px) {
      margin-top: 0;
      height: calc(100vh - 56px);
    }
    .report-filter-nav {
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      margin: 0;
      padding: 0;
      .disabled {
        pointer-events: none;
        opacity: 0.6;
      }
      .item-exit {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
      }
      .item-exit-active {
        opacity: 0;
        transform: translateX(-100%);
      }
      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0rem 1rem 0rem 0rem;
        .delete-icon {
          cursor: pointer;
        }
        @media screen and (min-width: 991px) {
          :hover {
            background-color: white;
            .delete-icon {
              visibility: visible;
              animation: fade 0.3s linear;
              @keyframes fade {
                0% {
                  opacity: 0;
                }
                100% {
                  opacity: 1;
                }
              }
            }
          }
          .delete-icon {
            visibility: hidden;
          }
        }
      }
      li a {
        color: #222328;
        font-size: 1rem;
        outline: none !important;
        box-shadow: none !important;
        border-left: 3px solid transparent;
        flex-grow: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @media screen and (min-width: 992px) and (max-width: 1200px) {
          padding-left: 10px;
          padding-right: 5px;
        }
        &.active {
          border-left: 3px solid #c10e21;
        }
      }
    }
    .report-filter-header {
      padding: 0 0.875rem 1.25rem 1.875rem;
      border-bottom: 1px solid #ebebeb;
      @media screen and (max-width: 767px) {
        padding-left: 1.25rem;
        padding-right: 0.875rem;
      }
      @media screen and (min-width: 992px) and (max-width: 1200px) {
        padding-left: 10px;
      }

      .back-btn {
        margin-bottom: 1rem;
        margin-left: -6px;
        a {
          text-decoration: none;
          display: block;
          font-weight: 500;
          font-size: 1rem;
          outline: none !important;
          box-shadow: none !important;
          @media screen and (min-width: 992px) and (max-width: 1200px) {
            font-size: 0.875rem;
          }
          i {
            font-size: 0.6rem;
            transform: rotate(90deg);
            display: inline-block;
            vertical-align: middle;
            &::before {
              height: auto;
              width: auto;
            }
            @media screen and (min-width: 992px) and (max-width: 1200px) {
              font-size: 0.475rem;
            }
          }
        }
      }
      .form-control {
        padding-right: 30px;
        border-color: #bcc3ca;
      }
      .icon-search {
        position: absolute;
        position: absolute;
        right: 10px;
        top: 13px;
        @media screen and (max-width: 575px) {
          top: 10px;
        }
      }
    }
  }
`;
export default LeftPanelWrapper;
